import PusherBatchAuthorizer from './PusherBatchAuthorizer'
// eslint-disable-next-line no-restricted-imports
import Pusher from 'pusher-js/with-encryption'

export function getPusherInstance() {
  if (window.pusherInstance) return window.pusherInstance

  const { HS, hsGlobal } = window
  const { csrfToken } = HS || {}
  const { pusherAppKey, pusherCluster } = hsGlobal.pusher || {}

  const options = {
    auth: {
      headers: {
        'X-CSRF-Token': csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    },
    authEndpoint: '/api/v0/pusher/auth',
    authorizer: PusherBatchAuthorizer,
    cluster: pusherCluster,
    encrypted: true,
    disableStats: true,
  }

  // Allow PusherClientSpec to override POST to GET for test purposes
  if (hsGlobal.pusher.authTransport) {
    options.authTransport = hsGlobal.pusher.authTransport
  }

  window.pusherInstance = new Pusher(pusherAppKey, options)

  return window.pusherInstance
}
